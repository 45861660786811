@import url('https://fonts.googleapis.com/css2?family=NTR&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles for consistent cross-browser rendering */
@layer base {
  html, body {
    @apply w-full overflow-x-hidden box-border;
  }
  
  * {
    @apply box-border;
  }
}
